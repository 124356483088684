@import "stylesheets/animations";

.container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background-color: var(--color-2);
    animation: $fadeIn;
}

.wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    display: flex;

    &:last-child {
        display: none;
    }
}

.authentication {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: var(--padding);
}

.box {
    width: 100%;
    max-width: 35rem;

    h1 {
        margin-bottom: 2rem;
        font-size: 3rem;
    }

    h1 + p {
        margin: -1rem 0 2rem 0;
        font-size: 1.4rem;
    }

    label:not(:last-of-type) {
        margin-bottom: 1.5rem;
    }

    label:last-of-type {
        margin-bottom: 0.5rem;
    }

    a {
        width: 100%;
        display: block;
        text-align: right;
        font-size: 1.4rem;
    }
}

.consent {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 2rem;

    label {
        margin-top: 0.2rem;
    }

    p {
        margin-left: 1rem;
        text-align: justify;
        font-size: 1.4rem;
    }
}

.buttonWrapper {
    width: 100%;
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
}

.button {
    width: 100%;
    height: 4.5rem;
    border-radius: var(--border-radius);
    background-color: var(--color-1);
    animation: $fadeIn;
    font-weight: 500;
    color: var(--color-2);
}

.notice {
    width: 100%;
    margin-top: 1rem;
    text-align: center;
    font-size: 1.2rem;

    a {
        width: unset;
        display: inline-block;
        font-size: inherit;
    }
}

.information {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: var(--padding);
    z-index: 2;
}

.background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: saturate(0.7);
    z-index: 0;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-2);
    opacity: 0.85;
    z-index: 1;
}

.content {
    width: 100%;
    max-width: 45rem;
}

.subtitle {
    line-height: 1.5;
    margin-bottom: 5rem;
    font-size: 2rem;
    font-weight: 500;
}

.item {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 4rem;

    div:first-child {
        margin-right: 1rem;
        padding-top: 0.2rem;
        font-size: 2.5rem;
    }

    h2 {
        margin-bottom: 0.7rem;
    }
}

.logos {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media screen and (min-width: 768px) {
    .wrapper {
        width: 50%;

        &:last-child {
            display: flex;
        }
    }
}