.wrapper {
    width: 100%;
    margin-top: 1rem;
}

.list {
    width: 100%;
    min-height: 25rem;
}

.invoice {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem;
    border-radius: var(--border-radius-large);
    transition: background-color 0.2s;
    cursor: pointer;

    &:not(:last-child) {
        margin-bottom: 1rem;
    }

    &:hover {
        background-color: var(--color-2-dimmed);
    }
}

.icon {
    width: 4rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 2rem;
    font-size: 3.5rem;
}

.number {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 600;

    span {
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 1.2rem;
        font-weight: 600;
    }
}

.circle {
    width: 1rem;
    height: 1rem;
    margin: 0 1rem;
    border-radius: 50%;
}

.paid {
    @extend .circle;
    background-color: var(--color-4);

    + span {
        color: var(--color-4);
    }
}

.unpaid {
    @extend .circle;
    background-color: var(--color-5);

    + span {
        color: var(--color-5);
    }
}

.timestamp {
    font-size: 1.2rem;
    font-weight: 500;
    color: var(--color-2-dark);
}