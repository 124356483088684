.box {
    position: relative;
    width: 100%;
    padding: 2rem 2rem 2rem 4rem;
    border-radius: var(--border-radius);
    font-size: 1.4rem;
}

.info {
    @extend .box;
    background-color: var(--color-2-darker);

    .icon {
        background-color: var(--color-3);
    }
}

.warning {
    @extend .box;
    background-color: var(--color-6-bright);

    .icon {
        background-color: var(--color-6);
    }
}

.alert {
    @extend .box;
    background-color: var(--color-5-bright);

    .icon {
        background-color: var(--color-5);
    }
}

.icon {
    position: absolute;
    top: -0.8rem;
    left: -0.8rem;
    width: 3.7rem;
    height: 3.7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 0.5rem solid var(--color-2);
    font-size: 2rem;
    color: var(--color-2);
}

.addTopMargin {
    margin-top: 2rem;
}

.addBottomMargin {
    margin-bottom: 2rem;
}