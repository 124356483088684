.banner {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 7rem;
    background-color: var(--color-1-transparent);
    backdrop-filter: blur(10px);
    transition: transform 0.4s;
    transform: translateY(110%);
    opacity: 1;

    > div {
        width: 100%;
        max-width: var(--max-width-narrow);
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
        padding: 0 var(--padding);
    }

    p {
        font-size: 1.4rem;
        font-weight: 500;
        color: var(--color-2);
    }
}

.wrapper {
    width: 12.5rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1rem;
}

.spinner {
    width: 2rem;
    height: 2rem;
    padding-right: 5rem;
    filter: invert(1);
}

.bannerOpen {
    @extend .banner;
    transform: translateY(0);
}