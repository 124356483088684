@import "stylesheets/animations";

.back {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 2rem;

    span {
        display: block;
        margin-left: 0.5rem;
        font-size: 1.6rem;
        font-weight: 600;
    }
}

.header {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
    padding: 5rem 2rem;
    border-radius: var(--border-radius-large);
    border: 1px solid var(--color-2-dimmed);
    box-shadow: var(--shadow);
    animation: $fadeIn;

    h1 {
        margin-top: 3rem;
    }

    a {
        font-size: 1.4rem;
        color: var(--color-2-dark);

        &:hover {
            color: var(--color-1);
        }
    }
}

.sectionTitle {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 7rem;
    margin-bottom: 4rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--color-2-contrast);
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5rem;
}