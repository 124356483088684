.container {
    width: 100%;
    max-width: 50rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 5rem 2rem 4rem 2rem;

    img {
        width: auto;
        height: 9rem;
        margin-bottom: 4rem;
        filter: invert(1);
    }

    h1 {
        margin: 0 0 1rem 0;
        font-size: 3.5rem;
    }

    > p {
        text-align: center;
    }
}

.inputWrapper {
    position: relative;
    width: 100%;
    margin: 2rem 0 1.5rem 0;

    input {
        padding-left: 15.2rem;
    }
}

.baseUrl {
    position: absolute;
    top: 0;
    left: 1rem;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 500;
    color: var(--color-2-dark);
}

.saveWrapper {
    width: 100%;
    height: 7rem;
    display: flex;
    justify-content: center;
    align-items: center;
}