@import "stylesheets/animations";

.list {
    width: 100%;
    min-height: 15rem;
    animation: $fadeIn;

    li:last-child {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        button {
            margin-top: 4rem;
            padding: 0.4rem 1rem;
            border-radius: 2rem;
            background-color: var(--color-1);
            font-size: 1.2rem;
            font-weight: 600;
            color: var(--color-2);
        }
    }
}

.employeeLogin {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-radius: var(--border-radius-large);
    background-color: var(--color-2);
    transition: transform 0.4s, background-color 0.2s, opacity 0.4s;

    &:hover {
        background-color: var(--color-2-dimmed);
    }
}

.name {
    flex: 1;
    padding-right: 1rem;
    font-size: 1.4rem;
    font-weight: 600;
}

.wrapper {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.2rem;

    &:first-of-type {
        margin-right: 2rem;
    }
}