$fadeInFromBottom: fadeInFromBottomAnimation 0.5s backwards;
$fadeIn: fadeInAnimation 0.7s backwards;

@keyframes fadeInFromBottomAnimation {
    from {
        transform: translateY(0.5rem);
        -webkit-transform: translateY(0.5rem);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        -webkit-transform: translateY(0);
        opacity: 1;
    }
}

@keyframes fadeInAnimation {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}