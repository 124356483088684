@import "stylesheets/animations";

.header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 7rem;

    h1 {
        margin: 0;
    }
}

.list {
    width: 100%;
}

.location {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 2rem;
    border-radius: var(--border-radius-large);
    transition: transform 0.4s, background-color 0.2s, opacity 0.4s;
    animation: $fadeInFromBottom;

    &:hover {
        background-color: var(--color-2-dimmed);
    }

    &:not(:last-child) {
        margin-bottom: 1.5rem;
    }
}

.wrapper {
    width: calc(100% - 3rem);
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
}

.box {
    width: calc(100% / 2);
    overflow: hidden;

    .title {
        max-width: 100%;
        margin-bottom: 0.2rem;
        padding-right: 1rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-weight: 600;
        overflow: hidden;
    }

    .subtitle {
        font-size: 1.4rem;
        font-weight: 500;
        color: var(--color-2-dark);
    }

    &:nth-child(2) {
        display: none;
    }
}

.actions {
    width: 5rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.action {
    font-size: 2.2rem;
}

@media screen and (min-width: 768px) {
    .box {
        width: calc(100% / 3);

        .title {
            max-width: 17rem;
        }

        &:nth-child(2) {
            display: block;
        }
    }
}