@import "stylesheets/animations";

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.qrCode {
    width: 159px;
    height: 159px;
    border-radius: var(--border-radius-large);
    border: 5px solid var(--color-2);
    animation: $fadeIn;
    overflow: hidden;
}

.larger {
    @extend .qrCode;
    width: 179px;
    height: 179px;
}

.download {
    width: 170px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    padding: 0.8rem 0;
    border-radius: 5rem;
    background-color: var(--color-1);
    font-size: 1.4rem;
    font-weight: 600;
    color: var(--color-2);
}