@import "stylesheets/animations";

.row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 2rem;
    margin-bottom: 2rem;

    > div {
        position: relative;
        width: calc(50% - 1rem);
    }
}

.currency {
    position: absolute;
    right: 0;
    bottom: 0.3rem;
    width: 5rem;
    height: 3.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
    background-color: var(--color-2-contrast);
    font-weight: 600;
    color: var(--color-2-dark);
}

.topMargin {
    margin-top: 2rem;
}

.box {
    width: 100%;
    margin-top: 2rem;
    animation: $fadeIn;
}

.wrapper {
    margin-top: 1.5rem;

    > div:not(:last-child) {
        margin-bottom: 1rem;
    }
}

.flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.4rem;
    font-weight: 500;
    color: var(--color-2-dark);
}

.controls {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 3rem;
}