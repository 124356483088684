@import "stylesheets/animations";

.banner {
    position: absolute;
    top: var(--nav-height);
    left: 0;
    width: 100%;
    height: 40rem;
    background-color: var(--color-4-bright);
    animation: $fadeIn;
    z-index: 1;
}

.content {
    position: relative;
    width: 100%;
    padding-bottom: 10rem;
    animation: $fadeIn;
    z-index: 2;
}

.header {
    width: 100%;
    height: calc(40rem - var(--padding));
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--padding);
    padding-bottom: var(--padding);

    p {
        margin-bottom: 2.5rem;
        font-size: 1.8rem;
        font-weight: 500;
    }

    a {
        display: inline-flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0.7rem 1.5rem;
        border-radius: 2rem;
        background-color: var(--color-2);
        font-weight: 600;
        font-size: 1.4rem;
        color: var(--color-1);

        span {
            margin-left: 0.7rem;
        }
    }
}

.progress {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 4rem;
    animation: $fadeInFromBottom;
}

.step {
    position: relative;
    width: 100%;

    div {
        font-weight: 600;
    }

    p {
        margin: 1rem 0 2rem 0;
        font-size: 1.4rem;
    }

    a,
    a:active {
        padding: 0.4rem 1rem;
        border-radius: 2rem;
        background-color: var(--color-1);
        font-size: 1.2rem;
        font-weight: 500;
        color: var(--color-2);
    }

    &:nth-child(2) {
        margin: 4rem 0;
    }
}

.tick {
    position: absolute;
    top: -1rem;
    right: 0;
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.5rem;
    color: var(--color-4);
}

.overlay {
    position: absolute;
    top: -1rem;
    bottom: -1rem;
    left: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 1;
}

@media screen and (min-width: 768px) {
    .progress {
        flex-direction: row;
    }

    .step {
        width: calc(100% / 3);

        &:nth-child(2) {
            margin: 0 3rem;
        }
    }
}