.nav {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: var(--nav-height);
    border-bottom: 1px solid var(--color-1-lighter);
    background-color: var(--color-1);
    backdrop-filter: blur(15px) saturate(1.2);
    box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.05);
    z-index: 99;
}

.container {
    position: relative;
    width: 100%;
    max-width: var(--max-width-narrow);
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 0 2rem;
}

.logo {
    width: auto;
    height: 38%;
}

.wrapper {
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.userWrapper {
    max-width: 20rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    transition: border 0.2s;
}

.user {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    margin-right: 1rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 1.2rem;
    font-weight: 500;
    color: var(--color-2);
    overflow: hidden;

    div {
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

.email {
    text-align: right;
    font-size: 1rem;
    font-weight: 400;
}

.avatar {
    width: 3.8rem;
    height: 3.8rem;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    padding-top: 0.2rem;
    border-radius: 50%;
    background-color: var(--color-2-dimmed);
    font-size: 2rem;
    font-weight: 500;
    color: var(--color-1);
}

.separator {
    width: 1px;
    height: 50%;
    margin: 0 2rem;
    background-color: var(--color-1-bright);
}

.burger {
    width: 3rem;
    height: 22px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    cursor: pointer;

    div {
        width: 100%;
        height: 2px;
        border-radius: 2rem;
        background-color: var(--color-2-dimmed);
        transition: width 0.4s;
    }

    div:nth-child(2) {
        width: 60%;
    }

    div:nth-child(3) {
        width: 80%;
    }

    &:hover div:nth-child(2),
    &:hover div:nth-child(3) {
        width: 100%;
    }
}

.burgerActive {
    @extend .burger;

    div:nth-child(2),
    div:nth-child(3) {
        width: 100%;
    }
}

.menu {
    position: absolute;
    top: 7.5rem;
    right: 2rem;
    width: 24rem;
    border-radius: var(--border-radius-large);
    border: 1px solid var(--color-2-darker);
    background-color: var(--color-2);
    box-shadow: var(--shadow);
    z-index: 999;

    ul {
        width: 100%;
        height: 100%;
    }

    li {

    }
}

.item {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.4rem;
    font-weight: 500;
    color: var(--color-2-dark);
    cursor: pointer;

    a {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 2rem;
        color: var(--color-2-dark);
    }

    &:hover a {
        color: var(--color-1);
    }

    &:last-child {
        padding: 2rem;
    }

    &:last-child:hover {
        color: var(--color-5);
    }

    &:not(:last-child) {
        border-bottom: 1px solid var(--color-2-darker);
    }
}

.icon {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 1rem;
    font-size: 2rem;
}

@media screen and (min-width: 992px) {
    .container {
        max-width: var(--max-width);
    }

    .userWrapper {
        max-width: 25rem;
    }

    .user {
        font-size: 1.4rem;
    }

    .email {
        font-size: 1.2rem;
    }
}