@import "stylesheets/animations";

.settings {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.menu {
    position: sticky;
    top: calc(var(--nav-height) + var(--padding) + 5.1rem);
    left: 0;
    display: none;
    border-right: 1px solid var(--color-2-contrast);
    margin-top: 5.1rem;
    margin-right: 5rem;
    padding: 2rem 2rem 2rem 0;
    animation: $fadeInFromBottom;
    animation-delay: 0.2s;
}

.item,
.item a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transition: transform 0.2s, color 0.2s;
    font-size: 1.4rem;
    font-weight: 500;
    color: var(--color-2-dark);
    cursor: pointer;

    &:hover {
        color: var(--color-1);
    }

    &:first-child {
        margin-bottom: 2rem;
    }

    &:not(:last-child) {
        margin-bottom: 2rem;
    }
}

.active {
    @extend .item;
    transform: scale(1.01);
    color: var(--color-1);
}

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.7rem;
    font-size: 2rem;
}

.content {
    flex: 1;
    animation: $fadeIn;

    section > *:not(:last-child) {
        margin-bottom: 2.5rem;
    }

    section:not(:first-of-type) {
        margin-top: 10rem;
    }

    h1 {
        margin-bottom: 1.5rem;
        font-size: 3rem;
    }

    h2 {
        margin-bottom: 1rem;
        font-size: 2.2rem;
    }

    p {
        font-size: 1.4rem;
    }

    p + button {
        margin-top: 1rem;
    }
}

.box {
    width: 100%;
    margin-top: 1.5rem;
}

.currentPlan {
    b {
        text-transform: uppercase;
        color: var(--color-3);
    }
}

@media screen and (min-width: 768px) {
    .menu {
        display: block;
    }
}