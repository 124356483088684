@import "stylesheets/animations";

.container {
    position: relative;
    width: 100%;
    height: 100%;
    animation: $fadeIn;
}

.spinner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-2);
}