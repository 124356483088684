@import "stylesheets/animations";

.status {
    width: 100%;
    height: 4rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 4rem;
}

.badge {
    padding: 0.4rem 1rem;
    border-radius: 2rem;
    background-color: var(--color-2-dimmed);
    animation: $fadeIn;
    animation-delay: 1.5s;
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--color-2-dark);
}

.filters {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4rem;

    h6 {
        font-size: 1.4rem;
        font-weight: 600;
        color: var(--color-2-dark);
    }

    > div {
        width: 100%;

        &:first-child {
            margin-bottom: 2rem;
        }
    }
}

.list {
    width: 100%;
    min-height: 25rem;
    animation: $fadeInFromBottom;
    animation-delay: 0.2s;

    li {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: transform 0.4s, opacity 0.4s;

        &:not(:last-child) {
            margin-bottom: 0.5rem;
        }
    }
}

.notice {
    margin-top: 2rem;
    text-align: center;
    font-size: 1.4rem;
    font-weight: 600;
    color: var(--color-3);
}

@media screen and (min-width: 768px) {
    .filters {
        flex-direction: row;

        > div {
            width: calc(50% - 1rem);

            &:first-child {
                margin-bottom: 0;
            }
        }
    }
}