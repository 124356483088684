@import "stylesheets/animations";

.row {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin: 2rem 0;

    > div {
        width: 100%;
    }

    > div:first-child {
        margin-bottom: 2rem;
    }
}

.preview {
    position: relative;
    width: 100%;
    height: 25rem;
    border-radius: var(--border-radius-large);
    border: 1px solid var(--color-2-contrast);
    overflow: hidden;
}

.bar {
    position: relative;
    width: 100%;
    height: 6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
    animation: $fadeInFromBottom;
    font-size: 3rem;
    color: var(--color-2);
    z-index: 2;
}

.logo {
    width: 10rem;
    height: 70%;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.items {
    position: relative;
    margin-top: 1.5rem;
    padding: 2rem;
    animation: $fadeInFromBottom;
    animation-delay: 0.4s;
    z-index: 2;

    div {
        width: 100%;
        height: 2.5rem;
        border-radius: 5rem;
        background-color: var(--color-2-contrast);
    }

    div:last-child {
        width: 80%;
        margin-top: 2rem;
    }
}

.coverImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    cursor: pointer;
    z-index: 1;
}

.overlay {
    position: absolute;
    top: 6rem;
    bottom: 4rem;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    background-color: var(--color-1-transparent);
    transition: opacity 0.4s;
    text-align: center;
    font-size: 1.4rem;
    font-weight: 600;
    color: var(--color-2);
    opacity: 0;
    cursor: pointer;
    z-index: 3;

    &:hover {
        opacity: 1;
    }
}

.wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-top: 1px solid var(--color-2-contrast);
    background-color: var(--color-2);
    z-index: 2;

    button {
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.2rem;
        font-weight: 500;
        color: var(--color-2-dark);

        &:not(:disabled):active {
            transform: unset;
        }

        &:first-child {
            border-right: 1px solid var(--color-2-contrast);
        }

        &:hover {
            color: var(--color-1);
        }
    }
}

.colorPicker {
    width: 100%;
    height: 25rem;
}

.placeholder {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    text-align: center;
    font-size: 1.4rem;
    font-weight: 600;
    cursor: pointer;
}

.controls {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 4rem;
}

@media screen and (min-width: 768px) {
    .row {
        flex-direction: row;

        > div {
            width: calc(50% - 1rem);
        }

        > div:first-child {
            margin-bottom: 0;
        }
    }
}