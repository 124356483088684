.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    h1 {
        margin: 0 0 1rem 0;
    }

    > p {
        max-width: 50rem;
        text-align: center;
    }

    > div {
        width: 100%;
    }
}

.pin {
    width: 100%;
    height: 12rem;
    margin-bottom: 2rem;
    text-align: center;
    font-size: 12rem;
    color: var(--color-2-contrast);
}

.row {
    width: 100%;
    margin-top: 2rem;
}

.controls {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 3rem;
}