.container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem;
    border-radius: var(--border-radius-large);
    background-color: var(--color-2);
    transition: transform 0.4s, background-color 0.2s, opacity 0.4s;

    &:hover {
        background-color: var(--color-2-dimmed);
    }
}

.name {
    flex: 1;
    padding-right: 1rem;
    font-size: 1.8rem;
    font-weight: 600;
}

.wrapper {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.2rem;

    &:first-of-type {
        margin-right: 4rem;
    }
}

.modal {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 4rem;
    text-align: center;

    h1 {
        margin: 3rem 0 1.5rem 0;
    }

    canvas {
        transform: scale(1.15);
    }
}

.controls {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 4rem;
}