.category {
    padding: 2rem 2rem 0 2rem;
    border-radius: var(--border-radius-large);
    border: 1px solid var(--color-2-contrast);
    background-color: var(--color-2);

    .category {
        border: 1px solid var(--color-2-dimmed);
        background-color: var(--color-2-dimmed);
    }

    &:not(:first-child) {
        margin-top: 2rem;
    }
}

.categoryHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem;
    border-radius: var(--border-radius);
    border: 1px solid var(--color-2-contrast);

    > div:first-child {
        flex: 1;
        transition: color 0.2s;
        cursor: pointer;

        &:hover {
            color: var(--color-1);
        }
    }
}

.title {
    transition: color 0.2s;
    font-size: 2rem;
    font-weight: 600;
}

.name {
    font-weight: 600;

    span {
        font-size: 1.4rem;
        color: var(--color-2-dark);
    }
}

.description {
    font-size: 1.4rem;
    font-weight: 500;
    color: var(--color-2-dark);
}

.actions {
    width: 8rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon {
    width: 3.2rem;
    height: 3.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin-right: 1rem;
    border-radius: 50%;
    border: 1px solid var(--color-2-darker);
    background-color: var(--color-2-dimmed);
    font-size: 1.8rem;
    color: var(--color-2-dark);

    &:last-child {
        margin-right: 0;
    }

    &:hover {
        color: var(--color-1);
    }
}

.add {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.5rem auto 1rem auto;
    font-size: 2.5rem;
    color: var(--color-1);
}

.addCategory {
    @extend .add;
    padding: 0.5rem 1rem;
    border-radius: 2rem;
    background-color: var(--color-1);
    font-size: 1.4rem;
    font-weight: 600;
    color: var(--color-2);

    .addIcon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 0.5rem;
        font-size: 1.8rem;
    }
}