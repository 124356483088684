@import "stylesheets/animations";

.container {
    width: 100%;
    animation: $fadeIn;
    animation-delay: 0.1s;
}

.back {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 3rem;
    font-size: 2rem;

    span {
        display: block;
        margin-left: 0.5rem;
        font-size: 1.6rem;
        font-weight: 600;
    }
}