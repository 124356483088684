.icon {
    position: relative;
    width: 2.1rem;
    height: 1.5rem;
    line-height: 1.5rem;
    display: inline-block;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    &:before {
        content: " "
    }
}

.addLeftMargin {
    margin-left: 1.5rem;
}

.al {
    @extend .icon;
    background-image: url("/assets/flags/AL.svg");
}

.de {
    @extend .icon;
    background-image: url("/assets/flags/DE.svg");
}

.dk {
    @extend .icon;
    background-image: url("/assets/flags/DK.svg");
}

.es {
    @extend .icon;
    background-image: url("/assets/flags/ES.svg");
}

.fr {
    @extend .icon;
    background-image: url("/assets/flags/FR.svg");
}

.gb,
.en {
    @extend .icon;
    background-image: url("/assets/flags/GB.svg");
}

.it {
    @extend .icon;
    background-image: url("/assets/flags/IT.svg");
}

.ru {
    @extend .icon;
    background-image: url("/assets/flags/RU.svg");
}

.se {
    @extend .icon;
    background-image: url("/assets/flags/SE.svg");
}