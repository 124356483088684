.badge {
    display: inline-block;
    margin-bottom: 0.5rem;
    padding: 0.4rem 0.8rem;
    border-radius: 2rem;
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 600;
}

.uncompleted {
    @extend .badge;
    background-color: var(--color-5-bright);
    color: var(--color-5);
}

.partlyCompleted {
    @extend .badge;
    background-color: var(--color-6-bright);
    color: var(--color-6);
}

.completed {
    @extend .badge;
    background-color: var(--color-4-bright);
    color: var(--color-4);
}

.row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 2rem;
    padding: 1.5rem;
    border-radius: var(--border-radius-large);
    background-color: var(--color-2-dimmed);

    > div {
        width: 50%;
    }

    > div:nth-child(-n + 2) {
        margin-bottom: 1.5rem;
    }
}

.title {
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 800;
    overflow: hidden;
}

.caption {
    font-size: 1.4rem;
    font-weight: 500;
    color: var(--color-2-dark);
}

.dottable td {
    max-width:200px;
    overflow:hidden;
    white-space:nowrap;
}
.dottable td:first-child:after {
    content:"................................................................................"
}

.controls {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 3rem;
}

@media screen and (min-width: 768px) {
    .row {
        margin-bottom: 4rem;

        > div {
            width: calc(25% - 1rem);
        }

        > div:nth-child(-n + 2) {
            margin-bottom: 0;
        }
    }
}