.container {
    margin-top: 0.5rem;
    border: 1px solid var(--color-2-dimmed);
    border-radius: var(--border-radius-large);
    overflow: hidden;
}

.wrapper {
    width: 100%;
    overflow: scroll;
}

.table {
    width: 100%;
    table-layout: auto;
    border-collapse: collapse;
    overflow: scroll;

    th {
        padding: 1.2rem 1rem;
        border-bottom: 1px solid var(--color-2-dimmed);
        background-color: var(--color-2-dimmed);
        text-transform: uppercase;
        font-size: 1rem;
        font-weight: 500;
        text-align: left;
        color: var(--color-1);
        cursor: pointer;
    }

    td {
        padding: 1.2rem 1rem;
        font-size: 1.2rem;
    }

    th:not(:last-child),
    td:not(:last-child) {
        border-right: 1px solid var(--color-2-dimmed);
    }

    tr:not(:last-child) {
        border-bottom: 1px solid var(--color-2-dimmed);
    }
}

.stretched {
    width: 100%;
}

.centered {
    text-align: center;
}

.alignRight {
    text-align: right;
}

.noWrap {
    white-space: nowrap;
}

@media screen and (min-width: 768px) {
    .table th {
        padding: 1.2rem 1.5rem;
        text-transform: unset;
        font-size: 1.4rem;
    }

    .table td {
        padding: 1.2rem 1.5rem;
        font-size: 1.4rem;
    }
}