@import "stylesheets/animations";

.container {
    width: 100%;
    max-width: 50rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 1rem 2rem 4rem 2rem;
    text-align: center;
    animation: $fadeIn;

    h1 {
        margin: 0 0 1rem 0;
        font-size: 3.5rem;
    }

    p {
        margin-bottom: 2rem;
    }
}

.tick {
    width: 18rem;
    height: 18rem;
}