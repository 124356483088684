.name {
    flex: 1;
    padding-right: 1rem;
    font-size: 1.8rem;
    font-weight: 600;
}

.container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem;
    border-radius: var(--border-radius-large);
    background-color: var(--color-2);
    transition: background-color 0.2s;
    cursor: pointer;

    > div {
        width: calc(100% / 3);

        &:not(:last-child) {
            padding-right: 1rem;
        }
    }

    &:hover {
        background-color: var(--color-2-dimmed);
    }
}

.number {
    font-size: 1.8rem;
    font-weight: 600;
}

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.badge {
    padding: 0.3rem 1rem;
    border-radius: 2rem;
    text-align: center;
    text-transform: uppercase;
    font-size: 1.1rem;
    font-weight: 600;
}

.uncompleted {
    @extend .badge;
    background-color: var(--color-5-bright);
    color: var(--color-5);
}

.partlyCompleted {
    @extend .badge;
    background-color: var(--color-6-bright);
    color: var(--color-6);
}

.completed {
    @extend .badge;
    background-color: var(--color-4-bright);
    color: var(--color-4);
}

.date {
    text-align: right;
    font-size: 1.4rem;
    font-weight: 500;
    color: var(--color-2-dark);
}