.settings {
    position: relative;
    width: 100%;
    margin-bottom: 4rem;
    padding: 4rem;
    border-radius: var(--border-radius-large);
    background-color: var(--color-1);
    box-shadow: var(--shadow);

    h1 {
        width: calc(100% - 4rem);
        margin: 0;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 2.5rem;
        color: var(--color-2);
        overflow: hidden;
    }

    h6 {
        margin-bottom: 1rem;
        font-weight: 600;
        color: var(--color-2);
    }
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4rem;
}

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.4rem 1rem;
    border-radius: 5rem;
    background-color: var(--color-2);
    font-size: 1.2rem;
    color: var(--color-1);

    span {
        display: block;
        margin-left: 0.5rem;
        font-size: 1.4rem;
        font-weight: 600;
    }
}

.container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.help {
    @extend .icon;
    position: absolute;
    top: -5.2rem;
    right: 0;
    background-color: var(--color-3-bright);
    font-size: 1.8rem;
    color: var(--color-3);
}

.wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.column {
    margin-right: 4rem;

    &:nth-child(2) {
        margin-right: 0;
    }
}

.logoWrapper,
.qrCodeWrapper {
    position: relative;
    width: 160px;
    height: 160px;
    padding: 1rem;
    border-radius: var(--border-radius-large);
    border: 1px dashed var(--color-2);
}

.logo {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.overlay {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1.4rem;
    font-weight: 600;
    color: var(--color-2);
}

.color {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    border: 1px dashed var(--color-2);
}

.qrCode {
    display: none;
}

.controls {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 4rem;
}

@media screen and (min-width: 768px) {
    .column {
        &:nth-child(2) {
            margin-right: 4rem;
        }
    }

    .qrCode {
        display: block;
    }
}